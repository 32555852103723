import React, { useState } from "react"
import Layout from "../components/Layout/Layout"
import Pricing from "../components/PricingTable/PricingTable"
import HowItWorks from "../components/HowItWorks/HowItWorks"
import SEO from "../components/Seo/Seo"
import Splash from "../components/Splash/Splash"
import Img from "gatsby-image"
import { useStaticQuery } from "gatsby"

const PlatformsPage = function () {
  const [tabName, setTabName] = useState("discover")

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "platform.png" }) {
        childImageSharp {
          fixed(width: 370, height: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout header={true} footer={true}>
      <SEO title="Home" />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column content is-two-thirds">
              <h1>For Ticket Platforms</h1>
              <div className="tabs">
                <ul>
                  <li className={tabName === 'discover' ? 'is-active' : ''}>
                    <a href="#" onClick={(e)=> {e.preventDefault(); setTabName('discover')}}>Features</a>
                  </li>
                  <li className={tabName === 'get-started' ? 'is-active' : ''}>
                    <a href="#" onClick={(e)=> {e.preventDefault(); setTabName('get-started')}}>Get Started</a>
                  </li>
                  <li className={tabName === 'integrate' ? 'is-active' : ''}>
                    <a href="#" onClick={(e)=> {e.preventDefault(); setTabName('integrate')}}>API integration</a>
                  </li>
                  <li className={tabName === 'demo' ? 'is-active' : ''}>
                    <a href="#" onClick={(e)=> {e.preventDefault(); setTabName('demo')}}>Demo</a>
                  </li>
                  <li className={tabName === 'help' ? 'is-active' : ''}>
                    <a href="#" onClick={(e)=> {e.preventDefault(); setTabName('help')}}>Need some help?</a>
                  </li>
                </ul>
              </div>

              {tabName === "discover" && (
                <div>
                  <h1>Features</h1>
                  <p>
                    We're a platform for hosting online events. We take the best
                    of what works in a physical venue and bring that online. We
                    have a wide range of features from simple video hosting to
                    crowd participation.
                  </p>
                  <h3>Video Formats</h3>
                  <p>
                    We provide a number of ways to stream your event. Event
                    Organisers can get started with just a basic webcam but we
                    also support a number of other technologies
                  </p>
                  <ul>
                    <li>Webcam support</li>
                    <li>MPEG-DASH support</li>
                    <li>RTSP support</li>
                  </ul>
                  <h3>See the crowd</h3>
                  <p>
                    One of the things people enjoy most with physical venues is
                    being able to see and interact with others. Out site
                    provides
                  </p>
                  <ul>
                    <li>See your crowd</li>
                    <li>Hear yur crowd</li>
                    <li>Allow them to talk to one another</li>
                  </ul>
                  <h3>Decorate your venue</h3>
                  <p>
                    We provide the ability to customise the page to suit the
                    type of event your hosting, be it a corporate presentation
                    or a heavy metal gig.
                  </p>
                  <ul>
                    <li>Choose from 3 themes or bring your own style</li>
                    <li>
                      Customise their video by adding backgrounds or even
                      lights!
                    </li>
                  </ul>
                  <h3>Use your own site</h3>
                  <p>
                    With VenueBox: Embed you can embed various widgets on to
                    your own site
                  </p>
                  <ul>
                    <li>Video box</li>
                    <li>Chat box</li>
                    <li>Crowd box for seeing others</li>
                  </ul>
                </div>
              )}
              {tabName === "get-started" && (
                <div>
                  <h1>Get Started</h1>
                  <p>Getting started is easy</p>
                  <ol>
                    <li>Sign up and register your platform</li>
                    <li>Generate an API Key</li>
                    <li>Integrate with our API</li>
                  </ol>
                  <h3>Sign up and register your platfirn</h3>
                  <p>
                    Vivamus porttitor nulla orci, nec cursus erat sollicitudin
                    vel. Nullam at blandit quam. Donec blandit, tellus non
                    dapibus volutpat, nisl risus pretium tortor, at efficitur
                    magna ipsum ac orci. Proin elit ipsum, rhoncus et ultrices
                    eget, mollis eget nulla. Vestibulum erat metus, lobortis a
                    congue sed, pharetra sodales est. Nulla vulputate pharetra
                    risus, at aliquet nunc congue eu. In nec lobortis nulla.
                  </p>
                </div>
              )}
            </div>

            <div className="column is-hidden-mobile">
              <Img fixed={data.file.childImageSharp.fixed} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PlatformsPage
